//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Cookies from 'js-cookie'
export default {
  name: 'LoginUserDialog',
  props: {
    showDialog: {
      type: Boolean,
      required: false,
    },
  },
  data: () => ({
    show_popup: false,
    ga_preference: 0,
    remember_me: 0,
  }),
  watch: {
    show_popup() {
      this.recheckCookieState()
    },
  },
  mounted() {
    this.recheckCookieState()
  },
  methods: {
    toggleRememberMe() {
      const remember_me = Cookies.get('remember-me') === '1' ? 0 : 1
      this.$store.dispatch('auth/setRememberMePreference', remember_me)
      this.remember_me = remember_me
      this.$nuxt.$emit('reloadCookieDisplay')
    },

    toggleAnalytics() {
      const analytics = Cookies.get('allow-analytics') === '1' ? 0 : 1 // toggle
      this.$store.dispatch('auth/setGAPreference', analytics)
      this.ga_preference = analytics
      this.$nuxt.$emit('reloadCookieDisplay')

      // handle if someone disables google analytics cookies durig myuse, while session cookies are enabled
      if (analytics === false) {
        this.$dialog.message.info(
          'Google Analytics has been disabled. Cookies will be removed on page reload.',
          {
            position: 'top',
            color: 'green',
            timeout: 3000,
          }
        )
      }
    },
    save() {
      this.show_popup = false
      this.$store.dispatch('navigation/storeNavigationHistory')
    },
    acceptAll() {
      // this.$toast.success('Accepted all cookies')
      this.$dialog.message.info('Accepted all cookies', {
        position: 'bottom',
        color: 'accent',
      })

      this.setCookies(true, true)
      this.show_popup = false
    },
    closeDialog() {
      this.show_popup = false
    },
    openDialog() {
      this.show_popup = true
    },
    recheckCookieState() {
      this.ga_preference = Cookies.get('allow-analytics') === '1' ? 1 : 0
      this.remember_me = Cookies.get('remember-me') === '1' ? 1 : 0
    },
  },
}
