//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CookieDialog from './CookieDialog.vue'

export default {
  name: 'CookieBanner',
  components: { CookieDialog },
  data: () => ({
    code: null,
    show_banner: false,
    show_popup: false,
    show: true,
    allow_clear_banner: false,
    slideMode: false,
    fade_done: false,
  }),
  computed: {
    imageHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return '200px'
        case 'sm':
          return '400px'
        case 'md':
          return '600px'
        case 'lg':
          return '800px'
        case 'xl':
          return '1000px'
      }
      return '0px'
    },
  },
  mounted() {
    // check if any cookies have been set
    const Cookie = this.$cookies.get('remember-me', { parseJSON: false })

    if (!Cookie) {
      this.slideToggle(true)
    } else {
      this.slideToggle(false)
      this.show_popup = true
      this.show_banner = false
    }
  },
  methods: {
    slideToggle(val) {
      setTimeout(() => {
        this.show_banner = val

        if (val === false) {
          setTimeout(() => {
            this.fadeOut()
          }, 200)
        }
      }, 200)
    },

    manage_coookies() {
      this.replacePopUp()
      this.$refs.dialog.openDialog()
    },
    acceptAll() {
      this.setCookies(1, 1)
    },
    rejectAll() {
      this.setCookies(0, 0)
    },
    closePopOut() {
      this.show_popup = false
    },
    replacePopUp() {
      this.show_popup = true
      this.slideToggle(false)
    },
    setCookies(session, analytics) {
      this.$store.dispatch('auth/setGAPreference', analytics)
      this.$store.dispatch('auth/setRememberMePreference', session)

      this.show_popup = true
      this.slideToggle(false)
    },
    fadeOut() {
      this.fade_done = true
    },
  },
}
